import { FittingRoom, SectionName } from "storefront/FittingRoom";
import { AlgoliaPager } from "storefront/Algolia/AlgoliaPager";
import { fittingRoomPager } from "../lib/GrailedAPI/v1/Users/Follows";

const determineViewing = (): SectionName => {
  let userIsViewing: SectionName;

  if (window.location.pathname.match(/sellers/)) {
    userIsViewing = "sellers";
  } else if (window.location.pathname.match(/collections/)) {
    userIsViewing = "capsules";
  } else if (window.location.pathname.match(/designers/)) {
    userIsViewing = "designers";
  } else if (window.location.pathname.match(/searches/)) {
    userIsViewing = "searches";
  } else {
    userIsViewing = "grails";
  }

  return userIsViewing;
};

const fittingRoom = ({
  FITTING_ROOM_USER_ID,
  FOLLOWED_SELLERS,
  MUTED_LISTING_IDS,
}: typeof window): FittingRoom => ({
  fittingRoomUserId: FITTING_ROOM_USER_ID || null,
  followedSellers: FOLLOWED_SELLERS || [],
  viewing: determineViewing(),
  grails: {
    loading: false,
    noMoreListings: false,
    listings: [],
    activeSort: "favorited_at",
    pager: fittingRoomPager(FITTING_ROOM_USER_ID, "favorited_at"),
  },
  sellers: {
    loading: false,
    noMoreListings: false,
    listings: [],
    pager: AlgoliaPager.listingsSoldBy(
      (FOLLOWED_SELLERS || []).map((seller) => seller.id),
    ),
  },
  capsules: {
    loading: false,
    noMoreListings: false,
    listings: [],
  },
  searches: {
    loading: false,
    noMoreListings: true,
    // this tab doesnt load listings
    listings: [],
  },
  designers: {
    loading: false,
    noMoreListings: true,
    listings: [],
  },
  mutedListingIds: MUTED_LISTING_IDS || [],
  muteRequestId: null,
});

export default fittingRoom;
