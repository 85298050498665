import nameToIndexSort from "storefront/Algolia/nameToIndexSort";
import * as Storage from "storefront/lib/Storage";
import * as Search from "./Search";

const sort = (w: typeof window): string => {
  const indexName = Search.maybeIndexName(Search.maybeFromWindow(w));

  if (indexName) {
    const algoliaIndexSort = nameToIndexSort(indexName);
    if (algoliaIndexSort) return algoliaIndexSort;
  }

  return (
    Storage.safelyGetItem(window.localStorage)("listings_sort") ||
    "price_updated_at desc"
  );
};

export default sort;
