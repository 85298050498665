/*
***DEPRECATION NOTICE!***
Hey there, don't add new actions here!
Add them to their own files in `storefront/GrailedAPI/v1/RESOURCE/`
*/
import { get, put, post, del, patch } from "storefront/GrailedAPI/request";
import type { Id } from "storefront/lib/Id";
import { isEmpty, omitBy } from "lodash/fp";
import { ListingCategorySlug } from "../types/ListingCategorySlug";
import type { CreateTrustScoreRequest } from "../components/internal_tools/user/TrustScores/form";

const GrailedAPI: Record<string, any> = Object.create(null);
GrailedAPI.designers = {
  all: () => get("/api/designers"),

  updateLogo(id: number, logoUrl: string | null | undefined): Promise<any> {
    return put(`/api/designers/${id}/logo`, {
      logoUrl,
    });
  },
};
GrailedAPI.listings = {
  fetchPage: (page: string) => get(page),

  // admin actions
  buyerFeedback: (id: Id) => get(`/api/listings/${id}/buyer_feedback`),
};
GrailedAPI.offers = {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'listing_id' implicitly has an 'any' typ... Remove this comment to see the full error message
  accept: (listing_id, amount, conversation_id) =>
    post("/api/offers/accept", {
      listing_id,
      amount,
      conversation_id,
    }),
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'listing_id' implicitly has an 'any' typ... Remove this comment to see the full error message
  acceptBindingOffer: (listing_id, amount, conversation_id) =>
    patch(
      `/api/binding_offers/${conversation_id}`,
      {
        listing_id,
        amount,
        accept: "true",
      },
      {
        // NOTE: we're using a longer timeout here because these requests to
        // PayPal tend to be much slower than we expect for other endpoints.
        timeout: 10000,
      },
    ),
};
GrailedAPI.conversations = {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'context' implicitly has an 'any' type.
  all: (context, archived) =>
    context
      ? get(`/api/conversations/${context}`, {
          archived,
        })
      : get("/api/conversations", {
          archived,
        }),
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'page' implicitly has an 'any' type.
  fetchPage: (page) => get(page),
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  markAsRead: (id) => put(`/api/conversations/${id}/mark_as_read`),
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  markAsUnread: (id) => put(`/api/conversations/${id}/mark_as_unread`),
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  archive: (id) => put(`/api/conversations/${id}/archive`),
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  unarchive: (id) => put(`/api/conversations/${id}/unarchive`),
  unreadCounts: () => get("/api/conversations/unread_counts"),
};

GrailedAPI.users = {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  sellerRatings: (id, page) =>
    get(`/api/users/${id}/seller_ratings`, {
      page,
    }),
  // TODO: put under /internal_tools?
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  ban: (id, reason, publicReason) =>
    post(`/api/users/${id}/ban`, {
      reason,
      public_reason: publicReason,
    }),
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  unban: (id, reason) =>
    post(`/api/users/${id}/unban`, {
      reason,
    }),
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'email' implicitly has an 'any' type.
  banByEmail: (email, reason, publicReason) =>
    post("/api/users/ban_by_email", {
      email,
      reason,
      public_reason: publicReason,
    }),
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'email' implicitly has an 'any' type.
  unbanByEmail: (email, reason) =>
    post("/api/users/unban_by_email", {
      email,
      reason,
    }),
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  warn: (id, flagId) =>
    post(`/api/users/${id}/warn`, {
      flag_id: flagId,
    }),
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  gentlyWarn: (id, flagId) =>
    post(`/api/users/${id}/gently_warn`, {
      flag_id: flagId,
    }),
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  block: (id) => put(`/api/users/${id}/block`),
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  unblock: (id) => put(`/api/users/${id}/unblock`),
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  getSettings: (id) => get(`/api/users/${id}/notification_settings`),
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  updateSettings: (id, settings) =>
    put(`/api/users/${id}/update_notification_settings`, {
      settings,
    }),
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  getNotificationGroupSettings: (id) =>
    get(`/api/users/${id}/notification_group_settings`),
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  updateNotificationGroupSettings: (id, newTargetItemSettings) =>
    put(
      `/api/users/${id}/update_notification_group_settings`,
      newTargetItemSettings,
    ),
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  unsubscribeNotificationGroupSettings: (id) =>
    put(`/api/users/${id}/unsubscribe_notification_group_settings`),
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'password' implicitly has an 'any' type.
  resetPassword: (password, passwordConfirmation, resetPasswordToken) =>
    put("/api/users/password", {
      password,
      passwordConfirmation,
      resetPasswordToken,
    }),
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  delete: (id) => post(`/api/users/${id}/delete`),
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  undelete: (id) => post(`/api/users/${id}/undelete`),
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  notifyRecentTransactorsOfBan: (id) =>
    post(`/api/users/${id}/notify_recent_transactors_of_ban`),
};
GrailedAPI.paypal = {
  merchants: {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'merchantId' implicitly has an 'any' typ... Remove this comment to see the full error message
    status: (merchantId) => get(`/api/paypal/merchants/${merchantId}/status`),
  },
};
GrailedAPI.groups = {
  all: () => get("/api/groups"),
};
GrailedAPI.savedFilters = {
  get: () => get("/api/saved_filters"),
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'filters' implicitly has an 'any' type.
  update: (filters) =>
    put("/api/saved_filters", {
      filters,
    }),
  destroy: () => del("/api/saved_filters"),
};
GrailedAPI.savedSearches = {
  get: () => get("/api/saved_searches"),
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'name' implicitly has an 'any' type.
  subscribe: (name, uuid) =>
    post(`/api/saved_searches/${uuid}/subscribe`, {
      name,
    }),
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'uuid' implicitly has an 'any' type.
  rename: (uuid, name) =>
    put(`/api/saved_searches/${uuid}/rename`, {
      name,
    }),
};
GrailedAPI.devices = {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'os' implicitly has an 'any' type.
  create: (os, os_version, device_token) =>
    post("/api/devices", {
      os,
      os_version,
      device_token,
    }),
};
GrailedAPI.internalTools = {
  banIP: (ip: string, reason: string) =>
    post("/internal/ban_ip", {
      ip,
      reason,
    }),
  unbanIP: (ip: string) =>
    post("/internal/unban_ip", {
      ip,
    }),
  userContent: (id: Id, category: ListingCategorySlug) =>
    get(`/internal/users/${id}/content`, {
      category,
    }),
  fetchUserListingsPage: (path: string) => get(path),
  banShippingAddress: (
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'line1' implicitly has an 'any' type.
    line1,
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'line2' implicitly has an 'any' type.
    line2,
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'city' implicitly has an 'any' type.
    city,
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
    state,
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'postalCode' implicitly has an 'any' typ... Remove this comment to see the full error message
    postalCode,
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'countryCode' implicitly has an 'any' ty... Remove this comment to see the full error message
    countryCode,
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'reason' implicitly has an 'any' type.
    reason,
  ) =>
    post("/internal/ban_shipping_address", {
      line1,
      line2,
      city,
      state,
      postalCode,
      countryCode,
      reason,
    }),
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'phoneNumber' implicitly has an 'any' ty... Remove this comment to see the full error message
  banPhoneNumber: (phoneNumber, reason) =>
    post("/internal/ban_phone_number", {
      phoneNumber,
      reason,
    }),
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'phoneNumber' implicitly has an 'any' ty... Remove this comment to see the full error message
  unbanPhoneNumber: (phoneNumber) =>
    post("/internal/unban_phone_number", {
      phoneNumber,
    }),
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'body' implicitly has an 'any' type.
  banPhrase: (body, notes) =>
    post("/internal/ban_phrase", {
      body,
      notes,
    }),
  stripeTransactions: (id: Id) =>
    get(`/api/internal/listings/${id}/stripe_transactions`),
  listingOrderCheckouts: (id: Id) => get(`/internal/listings/${id}/checkouts`),
  listingPurchaseUnitCaptures: (id: Id) =>
    get(`/internal/listings/${id}/captures`),
  listingCaptureDisbursements: (id: Id) =>
    get(`/internal/listings/${id}/disbursements`),
  listingCaptureRefunds: (id: Id) => get(`/internal/listings/${id}/refunds`),
  merchantSellerBuyerIds: (id: Id) =>
    get(`/internal/users/${id}/pay_pal_merchant_ids`),
};
GrailedAPI.flags = {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'type' implicitly has an 'any' type.
  reserve: (type) =>
    post("/api/flags/reserve", {
      type,
    }),
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  ignore: (id) => put(`/api/flags/${id}/ignore`),
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  resolve: (id, resolutions) =>
    put(`/api/flags/${id}/resolve`, {
      resolutions,
    }),
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  get: (id) => get(`/api/flags/${id}`),
};

GrailedAPI.articles = {
  all: (title?: string) => get("/api/articles", omitBy(isEmpty, { title })),
  fetchPage: (page: string) => get(page),
  create: (
    article: Record<string, any>,
    publicationNotification: string | null | undefined,
  ) => {
    const params = {
      author: article.author,
      position: article.position,
      category_slug: article.categorySlug,
      title: article.title,
      meta_title: article.metaTitle,
      subtitle: article.subtitle,
      slug: article.slug,
      content: article.content,
      content_type: article.contentType,
      published: article.published,
      published_at: article.publishedAt,
      hero: article.hero,
      hero_label: article.heroLabel,
      meta_description: article.metaDescription,
      tag_list: article.tagList,
      publication_notification: publicationNotification,
    };
    return post("/api/articles", params);
  },
  update: (
    id: Id,
    article: Record<string, any>,
    publicationNotification: string | null | undefined,
  ) => {
    const params = {
      author: article.author,
      position: article.position,
      category_slug: article.categorySlug,
      title: article.title,
      meta_title: article.metaTitle,
      subtitle: article.subtitle,
      slug: article.slug,
      content: article.content,
      content_type: article.contentType,
      published: article.published,
      published_at: article.publishedAt,
      meta_description: article.metaDescription,
      hero: article.hero,
      hero_label: article.heroLabel,
      tag_list: article.tagList,
      publication_notification: publicationNotification,
    };
    return put(`/api/articles/${id}`, params);
  },
  delete: (id: Id) => del(`/api/articles/${id}`),
  setHomepagePreview: (id: Id, position: number) =>
    post(`/api/articles/${id}/set_homepage_preview`, {
      position,
    }),
};

GrailedAPI.trustScores = {
  all: (user_id: Id) => get(`/api/users/${user_id}/trust_scores`),
  create: (user_id: Id, params: CreateTrustScoreRequest) =>
    post(`/api/users/${user_id}/trust_scores`, params),
};

if (typeof window !== "undefined") {
  window.GrailedAPI = GrailedAPI;
}

export default GrailedAPI;
