import {
  FETCH_TRUST_SCORES_REQUEST,
  FETCH_TRUST_SCORES_ERROR,
  FETCH_TRUST_SCORES_SUCCESS,
  CREATE_TRUST_SCORE_REQUEST,
  CREATE_TRUST_SCORE_ERROR,
  CREATE_TRUST_SCORE_SUCCESS,
} from "../constants/action_types";
import GrailedAPI from "../lib/grailed_api";
import type { TrustScore } from "../types/TrustScore";

export function createTrustScoreSuccess(
  trustScore: TrustScore,
): Record<string, any> {
  return {
    type: CREATE_TRUST_SCORE_SUCCESS,
    payload: {
      trustScore,
    },
  };
}
export function createTrustScoreError(error: Error): Record<string, any> {
  return {
    type: CREATE_TRUST_SCORE_ERROR,
    payload: {
      error,
    },
  };
}
export function createTrustScoreRequest(): Record<string, any> {
  return {
    type: CREATE_TRUST_SCORE_REQUEST,
    payload: {},
  };
}
export function fetchTrustScoresRequest(): Record<string, any> {
  return {
    type: FETCH_TRUST_SCORES_REQUEST,
    payload: {},
  };
}
export function fetchTrustScoresSuccess(
  trustScores: Array<TrustScore>,
): Record<string, any> {
  return {
    type: FETCH_TRUST_SCORES_SUCCESS,
    payload: {
      data: trustScores,
    },
  };
}
export function fetchTrustScoresError(error: Error): Record<string, any> {
  return {
    type: FETCH_TRUST_SCORES_ERROR,
    payload: {
      error,
    },
  };
}
export function fetchTrustScores(userId: number) {
  return (dispatch: (...args: Array<any>) => any) => {
    dispatch(fetchTrustScoresRequest());
    GrailedAPI.trustScores
      .all(userId)
      .then((response: { data: Array<TrustScore> }) => {
        dispatch(fetchTrustScoresSuccess(response.data));
      })
      .catch((error: Error) => dispatch(fetchTrustScoresError(error)));
  };
}
